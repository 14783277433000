export const urlExpedismart = 'https://staging.expedismart.ch';
export const newadmindb = 'https://newadmindb.weboost.dev';
const alloallo = 'http://reactjs.alloallo.ch';


export const URL_All = {
    Login: urlExpedismart + '/api-token-auth/',
    VerifyLoginUsername: newadmindb + '/basic/web/index.php/users/verify_user?username=',
    TrackingList: urlExpedismart + '/api/shipments/tracking',
    apiShipment: urlExpedismart + '/api/shipments',
    TrackingList2: newadmindb + '/basic/web/index.php/statusshipments/get_list_trackings',
    ArchiveTrackingList: newadmindb + '/basic/web/index.php/statusshipments/get_list_archives',
    TrackingListArchive: urlExpedismart + '/api/shipments/archived',
    SearchShipment: urlExpedismart + '/api/shipments/search/?q=',
    ContactList: urlExpedismart + '/api/customers',
    ShipmentList: urlExpedismart + '/api/shipments/?filter=',
    CustomerInfo: urlExpedismart + '/api/shipments/customer',
    CommentTask: newadmindb + '/basic/web/index.php/comments',
    TaskManageByUsername: newadmindb + '/basic/web/index.php/taskusers/get_by_names?sort=-created_at',
    // TaskManageByUsername: newadmindb + '/basic/web/index.php/taskusers/get_by_names',
    TaskManageDateNew: newadmindb + '/basic/web/index.php/taskusers/get_list?sort=-id',
    TaskManageDateOld: newadmindb + '/basic/web/index.php/taskusers/get_list?sort=+id',
    TaskManageTrNbrNew: newadmindb + '/basic/web/index.php/taskusers/get_list?sort=+tracking_nbr',
    TaskManageTrNbrOld: newadmindb + '/basic/web/index.php/taskusers/get_list?sort=-tracking_nbr',
    TaskManageDueDateNew: newadmindb + '/basic/web/index.php/taskusers/get_list?sort=-due_date',
    TaskManageDueDateOld: newadmindb + '/basic/web/index.php/taskusers/get_list?sort=+due_date',
    TaskManageUserCount: newadmindb + '/basic/web/index.php/taskusers/get_task_count',
    Users: newadmindb + '/basic/web/index.php/users',
    Statuslists: newadmindb + '/basic/web/index.php/statuslists',
    getStatusshipments: newadmindb + '/basic/web/index.php/statusshipments/get_list',
    getStatusTodayChecked: newadmindb + '/basic/web/index.php/statusshipments/get_list_today?updated=1&sort=-shipment_id',
    postEditStatusshipments: newadmindb + '/basic/web/index.php/statusshipments',
    UploadPost: alloallo + '/uploads',
    ShipmentTaskCommentCount: newadmindb + '/basic/web/index.php/comments/get_shipments_count',
    CustomerTaskCommentCount: newadmindb + '/basic/web/index.php/comments/get_customers_count',
    EnTransit: urlExpedismart + '/api/tracking/?s=',
    taskAdditionalDB: newadmindb + '/basic/web/index.php/taskusers/edit_task?task_id=',
    sendIDsBack: newadmindb + '/basic/web/index.php/statusshipments/update_list',
    // downloadInvoiceByID: 'https://thingproxy.freeboard.io/fetch/' + urlExpedismart + '/en/user/download_invoice_by_id?invoice_id=',
    downloadInvoiceByID: urlExpedismart + '/en/user/download_invoice/?ship_id=',
    downloadAwbShipID: urlExpedismart + '/en/download_awb/?ship_id=',
    downloadProforma: urlExpedismart + '/download_proforma/?ship_id=',
    adjustments: urlExpedismart + '/api/customers/adjustments',
    transporters: urlExpedismart + '/api/customers/transporters',
    customersviewset: urlExpedismart + '/api/customersviewset',
    conversionfunnels: urlExpedismart + '/api/conversionfunnels/',
        stats: urlExpedismart + '/api/stats',
};

// https://thingproxy.freeboard.io/fetch/
